@import url('https: //fonts.googleapis.com/css2?family=Akshar:wght@300;400;500;600;700&family=Poppins:ital,wght@0,300;0,400;0,500;1,200;1,300;1,400;1,500&display=swap');

.productsHeading {
    width: 100%;
    height: 20%;
    padding-top: 2%;
}

.ourProductsHeading {
    display: inline;
    text-align: center;
    margin: 10 auto;
    font-family: 'Akshar';
    font-style: normal;
    font-weight: 500;
    font-size: 70px;
    line-height: 84px;
    color: black;
    padding-top: 1rem;
    cursor: pointer;
    position: relative;
    text-decoration: none;
}

.ourProductsHeading:before {
    content: "";
    position: absolute;
    bottom: 20px;
    height: 10px;
    width: 30%;
    background: #FBB600;
    border-radius: 50px;
    transition: width 0.8s ease;
}

.ourProductsHeading:hover:before {
    width: 100%;
}

.productsContainer {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}

.productsContainer .productInfo {
    padding: 10px;
}

.productslogoBox .productsLogo {
    width: 500px;
    height: 500px;
    text-align: center;
    justify-content: center;
    padding: 0% 5% 0% 0%;
}

.productsHeadline {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 60px;
    padding-top: 20px;
    line-height: 96px;
    color: #0F0F0F;
    text-align: left;
    padding-left: 5%;
}

.productsInfoText {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 22px;
    line-height: 33px;
    color: #0F0F0F;
    padding: 0% 5% 5% 5%;
    text-align: justify;
}

.btn {
    width: 6rem;
    height: 1.5rem;
    text-align: center;
    font-family: 'Poppins';
    font-style: normal;
    font-size: 14px;
    color: #0F0F0F;
    border: 1px solid silver;
    background-color: rgb(183, 218, 229);
    border-radius: 20px;
}


@media only screen and (max-width: 480px) and (min-width: 320px) {
    .productsHeading {
        width: 100%;
        height: 20%;
    }

    .ourProductsHeading {
        display: inline;
        text-align: center;
        margin: 10 auto;
        font-family: 'Akshar';
        font-style: normal;
        font-weight: 500;
        font-size: 56px;
        line-height: 84px;
        color: black;
        padding-top: 1rem;
        cursor: pointer;
        position: relative;
        text-decoration: none;
    }

    .ourProductsHeading:before {
        content: "";
        position: absolute;
        bottom: 15px;
        height: 10px;
        width: 30%;
        background: #FBB600;
        border-radius: 50px;
        transition: width 0.8s ease;
    }

    .ourProductsHeading:hover:before {
        width: 100%;
    }

    .productsContainer {
        width: 100%;
        display: flex;
        flex-direction: column-reverse;
    }

    .productInfo {
        width: 100%;
        padding: 0;
    }

    .productslogoBox {
        width: 100%;
    }

    .productslogoBox .productsLogo {
        width: 200px;
        height: 200px;
        padding: 0px;
    }

    .productsHeadline {
        position: relative;
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 600;
        font-size: 30px;
        line-height: 96px;
        color: #0F0F0F;
        align-items: center;
        justify-content: center;

    }

    .productsInfoText {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 33px;
        color: #0F0F0F;
        padding: 5px;

    }

    .btn {
        width: 6rem;
        height: 1.5rem;
        text-align: center;
        font-family: 'Poppins';
        font-style: normal;
        font-size: 14px;
        color: #0F0F0F;
        border: 1px solid silver;
        background-color: rgb(183, 218, 229);
        border-radius: 20px;
    }

}

@media (min-width: 768px) and (max-width: 1024px) {
    .productsHeading {
        width: 100%;
        height: 20%;
    }

    .ourProductsHeading {
        display: inline;
        text-align: center;
        margin: 10 auto;
        font-family: 'Akshar';
        font-style: normal;
        font-weight: 500;
        font-size: 56px;
        line-height: 84px;
        color: black;
        padding-top: 1rem;
        cursor: pointer;
        position: relative;
        text-decoration: none;
    }

    .ourProductsHeading:before {
        content: "";
        position: absolute;
        bottom: 15px;
        height: 10px;
        width: 30%;
        background: #FBB600;
        border-radius: 50px;
        transition: width 0.8s ease;
    }

    .ourProductsHeading:hover:before {
        width: 100%;
    }

    .productsContainer {
        width: 100%;
        display: flex;
        flex-direction: column-reverse;
    }

    .productInfo {
        width: 100%;
        padding: 0;
    }

    .productslogoBox {
        width: 100%;
    }

    .productslogoBox .productsLogo {
        width: 200px;
        height: 200px;
        padding: 0px;
    }

    .productsHeadline {
        position: relative;
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 600;
        font-size: 30px;
        line-height: 96px;
        color: #0F0F0F;
        align-items: center;
        justify-content: center;

    }

    .productsInfoText {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 33px;
        color: #0F0F0F;
        padding: 10px;
    }

    .btn {
        width: 6rem;
        height: 1.5rem;
        text-align: center;
        font-family: 'Poppins';
        font-style: normal;
        font-size: 14px;
        color: #0F0F0F;
        border: 1px solid silver;
        background-color: rgb(183, 218, 229);
        border-radius: 20px;
    }




}