.AboutHeading {
  width: 100%;
  height: 20%;
  padding-top: 50px;
}

.AboutUsHeading {
  display: inline;
  text-align: center;
  margin: 10 auto;
  font-family: 'Akshar';
  font-style: normal;
  font-weight: 500;
  font-size: 70px;
  line-height: 84px;
  color: black;
  padding-top: 1rem;
  cursor: pointer;
  position: relative;
  text-decoration: none;
}

.AboutUsHeading:before {
  content: "";
  position: absolute;
  bottom: 20px;
  height: 10px;
  width: 30%;
  background: #FBB600;
  border-radius: 50px;
  transition: width 0.8s ease;
}

.AboutUsHeading:hover:before {
  width: 100%;
}

.aboutUsContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

.aboutTextBox {
  text-align: justify;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 26px;
  line-height: 45px;
  padding: 0 0 0 50px;
  margin-top: 100px;
}

.aboutUsContainer .main {
  width: 650px;
  height: 650px;
  position: relative;
  padding: 50px;
}

.bigCircle {
  height: 100%;
  width: 100%;
  content: "";
  border: 3px solid black;
  border-radius: 50%;
  display: flex;
  display: -webkit-flex;
  align-items: center;
  -webkit-align-items: center;
  justify-content: center;
  -webkit-justify-content: center;
  transform: translate(-50%, -50%) rotate(45deg);
  -webkit-transform: translate(0%, 0%) rotate(25deg);
}



.iconBlock {
  width: 64px;
  height: 64px;
  position: absolute;
  border-radius: 50%;
  display: flex;
  display: -webkit-flex;
  align-items: center;
  -webkit-align-items: center;
  justify-content: center;
}

.iconBlock img {
  margin: 0px auto;
  width: 50px;
  transform: translate(-50%, -50%) rotate(45deg);
  -webkit-transform: translate(-1000%, -3500%) rotate(-45deg);
  transition: 1s ease-in-out;
}

.main:hover .iconBlock img {
  display: block;
  transform: translate(-50%, -50%) rotate(45deg);
  -webkit-transform: translate(0%, 0%) rotate(-50deg);
}

.iconBlock:first-child {
  top: 0%;
  left: 40%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
}

.iconBlock:nth-child(2) {
  top: 20%;
  right: 10%;
  transform: translate(50%, -50%);
  -webkit-transform: translate(50%, -50%);
  position: absolute;
}

.iconBlock:nth-child(3) {
  bottom: 10%;
  left: 20%;
  transform: translate(-50%, 50%);
  -webkit-transform: translate(-50%, 50%);
}

.iconBlock:nth-child(4) {
  top: 40%;
  left: 0%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
}

/* circle content */
.circle {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(45deg);
  -webkit-transform: translate(-50%, -50%) rotate(45deg);
  width: 65%;
  height: 65%;
  border: 3px solid black;
  border-radius: 50%;
}



/* center logo */
.centerLogo {
  position: absolute;
  top: 68%;
  left: 45%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
}

.centerLogo img {
  max-width: 800px;
}


/* keyframe animation */

@keyframes Rotate {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

@-webkit-keyframes Rotate {
  from {
    -webkit-transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes Rotate-reverse {
  from {
    transform: rotate(360deg);
  }

  to {
    transform: rotate(0deg);
  }
}

@-webkit-keyframes Rotate-reverse {
  from {
    -webkit-transform: rotate(360deg);
  }

  to {
    -webkit-transform: rotate(0deg);
  }
}

@keyframes circle-rotate {
  from {
    transform: translate(-50%, -50%) rotate(45deg);
  }

  to {
    transform: translate(-50%, -50%) rotate(405deg);
  }
}

@-webkit-keyframes circle-rotate {
  from {
    -webkit-transform: translate(-50%, -50%) rotate(45deg);
  }

  to {
    -webkit-transform: translate(-50%, -50%) rotate(405deg);
  }
}

@keyframes img-rotate {
  from {
    transform: rotate(-45deg);
  }

  to {
    transform: rotate(-405deg);
  }
}

@-webkit-keyframes img-rotate {
  from {
    -webkit-transform: rotate(-45deg);
  }

  to {
    -webkit-transform: rotate(-405deg);
  }
}



@media only screen and (max-width: 480px) and (min-width: 320px) {

  .AboutHeading {
    width: 100%;
    height: 20%;
    padding-top: 50px;
  }

  .AboutUsHeading {
    display: inline;
    text-align: center;
    margin: 10 auto;
    font-family: 'Akshar';
    font-style: normal;
    font-weight: 500;
    font-size: 56px;
    line-height: 84px;
    color: black;
    padding-top: 1rem;
    cursor: pointer;
    position: relative;
    text-decoration: none;
  }

  .AboutUsHeading:before {
    content: "";
    position: absolute;
    bottom: 15px;
    height: 10px;
    width: 30%;
    background: #FBB600;
    border-radius: 50px;
    transition: width 0.8s ease;
  }

  .AboutUsHeading:hover:before {
    width: 100%;
  }

  .aboutUsContainer {
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-evenly;
    align-items: center;
  }

  .aboutTextBox {
    text-align: justify;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 45px;
    justify-content: center;
    align-items: center;
    padding: 0px 20px 20px 20px;
    margin: 10px auto;
  }

  .aboutUsContainer .main {
    width: 450px;
    height: 450px;
    position: relative;
    padding: 50px;
  }

  .bigCircle {
    height: 100%;
    width: 100%;
    content: "";
    border: 3px solid black;
    border-radius: 50%;
    display: flex;
    display: -webkit-flex;
    align-items: center;
    -webkit-align-items: center;
    justify-content: center;
    -webkit-justify-content: center;
    transform: translate(-50%, -50%) rotate(45deg);
    -webkit-transform: translate(0%, 0%) rotate(25deg);
  }

  .iconBlock {
    width: 64px;
    height: 64px;
    position: absolute;
    border-radius: 50%;
    display: flex;
    display: -webkit-flex;
    align-items: center;
    -webkit-align-items: center;
    justify-content: center;
  }



  .iconBlock img {
    display: block;
    width: 30px;
    transform: translate(-50%, -50%) rotate(45deg);
    -webkit-transform: translate(0%, 0%) rotate(-50deg);
  }

  .iconBlock:first-child {
    top: 0%;
    left: 40%;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
  }

  .iconBlock:nth-child(2) {
    top: 20%;
    right: 10%;
    transform: translate(50%, -50%);
    -webkit-transform: translate(50%, -50%);
    position: absolute;
  }

  .iconBlock:nth-child(3) {
    bottom: 10%;
    left: 20%;
    transform: translate(-50%, 50%);
    -webkit-transform: translate(-50%, 50%);
  }

  .iconBlock:nth-child(4) {
    top: 40%;
    left: 0%;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
  }

  /* circle content */
  .circle {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(45deg);
    -webkit-transform: translate(-50%, -50%) rotate(45deg);
    width: 60%;
    height: 60%;
    border: 3px solid black;
    border-radius: 50%;
  }



  /* center logo */
  .centerLogo {
    position: absolute;
    top: 65%;
    left: 45%;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
  }

  .centerLogo img {
    max-width: 450px;
  }

}


@media (min-width: 768px) and (max-width: 1024px) {


  .AboutHeading {
    width: 100%;
    height: 20%;
    padding-top: 50px;
  }

  .AboutUsHeading {
    display: inline;
    text-align: center;
    margin: 10 auto;
    font-family: 'Akshar';
    font-style: normal;
    font-weight: 500;
    font-size: 56px;
    line-height: 84px;
    color: black;
    padding-top: 1rem;
    cursor: pointer;
    position: relative;
    text-decoration: none;
  }

  .AboutUsHeading:before {
    content: "";
    position: absolute;
    bottom: 15px;
    height: 10px;
    width: 30%;
    background: #FBB600;
    border-radius: 50px;
    transition: width 0.8s ease;
  }

  .AboutUsHeading:hover:before {
    width: 100%;
  }

  .aboutUsContainer {
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-evenly;
    align-items: center;
  }

  .aboutTextBox {
    text-align: justify;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 45px;
    justify-content: center;
    align-items: center;
    padding: 0px 20px 20px 20px;
    margin: 10px auto;
  }

  .aboutUsContainer .main {
    width: 450px;
    height: 450px;
    position: relative;
    padding: 50px;
  }

  .bigCircle {
    height: 100%;
    width: 100%;
    content: "";
    border: 3px solid black;
    border-radius: 50%;
    display: flex;
    display: -webkit-flex;
    align-items: center;
    -webkit-align-items: center;
    justify-content: center;
    -webkit-justify-content: center;
    transform: translate(-50%, -50%) rotate(45deg);
    -webkit-transform: translate(0%, 0%) rotate(25deg);
  }

  .iconBlock {
    width: 64px;
    height: 64px;
    position: absolute;
    border-radius: 50%;
    display: flex;
    display: -webkit-flex;
    align-items: center;
    -webkit-align-items: center;
    justify-content: center;
  }

  /* .iconBlock img {
    margin: 0px auto;
    width: 30px;
    transform: translate(-50%, -50%) rotate(45deg);
    -webkit-transform: translate(-1000%, -3500%) rotate(-45deg);
    transition: 1s ease-in-out;
  } */

  .iconBlock img {
    display: block;
    width: 35px;
    transform: translate(-50%, -50%) rotate(45deg);
    -webkit-transform: translate(0%, 0%) rotate(-50deg);
  }

  .iconBlock:first-child {
    top: 0%;
    left: 40%;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
  }

  .iconBlock:nth-child(2) {
    top: 20%;
    right: 10%;
    transform: translate(50%, -50%);
    -webkit-transform: translate(50%, -50%);
    position: absolute;
  }

  .iconBlock:nth-child(3) {
    bottom: 10%;
    left: 20%;
    transform: translate(-50%, 50%);
    -webkit-transform: translate(-50%, 50%);
  }

  .iconBlock:nth-child(4) {
    top: 40%;
    left: 0%;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
  }

  /* circle content */
  .circle {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(45deg);
    -webkit-transform: translate(-50%, -50%) rotate(45deg);
    width: 60%;
    height: 60%;
    border: 3px solid black;
    border-radius: 50%;
  }



  /* center logo */
  .centerLogo {
    position: absolute;
    top: 65%;
    left: 45%;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
  }

  .centerLogo img {
    max-width: 450px;
  }
}