@import url('https://fonts.googleapis.com/css2?family=Akshar:wght@300;400;500;600;700&display=swap');


* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

.navbar {
    width: 100%;
    display: flex;
    justify-content: space-around;
    background: #333;
    color: #fff;
    align-items: center;
    padding: 10px;
}

.navbarBrand {
    max-width: 30%;
    width: 100%;
    display: flex;
}

.navbarBrand .navbarBrandLogo {
    width: 150px;
    justify-content: flex-start;
}

.navbarItemsDesktop {
    display: flex;
    max-width: 55%;
    width: 100%;
    justify-content: space-around;
}

.navbarItemDesktop {
    margin: 0 10px;
    font-family: 'Akshar';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 22px;
    color: #F2F2F2;
    text-decoration: none;
}

.navItemMobile {
    position: relative;
    margin: 10px 60px;
    font-family: 'Akshar';
    font-style: normal;
    font-weight: 100;
    font-size: 20px;
    color: black;
    text-decoration: none;
    padding: 0px 30px 0px 20px;
}
.MuiSvgIcon-root {
    color: white;
}