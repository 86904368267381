.careerHeading {
    width: 100%;
    height: 20%;
    padding-top: 2%;
}

.ourcareerHeading {
    display: inline;
    text-align: center;
    margin: 10 auto;
    font-family: 'Akshar';
    font-style: normal;
    font-weight: 500;
    font-size: 70px;
    line-height: 84px;
    color: white;
    padding-top: 1rem;
    cursor: pointer;
    position: relative;
    text-decoration: none;
}

.ourcareerHeading:before {
    content: "";
    position: absolute;
    bottom: 20px;
    height: 10px;
    width: 30%;
    background: #FBB600;
    border-radius: 50px;
    transition: width 0.8s ease;
}

.ourcareerHeading:hover:before {
    width: 100%;
}

.bgColor {
    background: #164037;
    padding: 0% 0% 5% 7%;
    width: 100%;
}

.openingsHeading {
    display: flex;
    justify-content: flex-start;
    font-family: Akshar;
    font-size: 40px;
    font-weight: 500;
    line-height: 44px;
    letter-spacing: 0em;
    text-align: center;
    color: white;
    padding: 5% 0% 5% 0%;
}

.roleBox {
    display: flex;
    flex-direction: column;
    gap: 50px;
    width: 90%;
}

.jobRoleBox {
    height: 130px;
    border-radius: 16px;
    opacity: 0.6000000238418579px;
    background: linear-gradient(90.55deg, rgba(189, 189, 189, 0.4) 0.78%, rgba(189, 189, 189, 0.3) 100%);
    display: flex;
    justify-content: space-between;
    padding: 2% 5% 2% 5%;
}

.jobroleTitle {
    font-family: Poppins;
    font-size: 30px;
    font-weight: 600;
    line-height: 60px;
    letter-spacing: 0em;
    color: white;
    align-self: center;
}

.jobroleApplybtn {
    width: 20%;
    height: 65px;
    border-radius: 16px;
    opacity: 0.6000000238418579px;
    align-self: center;
    /* background: linear-gradient(90.55deg, rgba(189, 189, 189, 0.4) 0.78%, rgba(189, 189, 189, 0.3) 100%); */
    background-color: #FBB600;
    border: none;
    font-family: Poppins;
    font-size: 24px;
    font-weight: 500;
    line-height: 36px;
    letter-spacing: 0em;
    text-align: center;
    color: #FFFFFF;
    cursor: pointer;
}

.showBg {
    background-image: url("../../common/Assets/CareerpageImages/human-resources-people-networking-concept.jpg");
    background-position: center;
    background-size: 100%;
    background-position: center center;
    background-repeat: no-repeat;
    background-attachment: fixed;
    z-index: 1000;
}



.bgImage {
    background-color: transparent;
    height: 60vh;
    z-index: -1;
}

.resumeSubmit {
    font-family: Akshar;
    font-size: 40px;
    font-weight: 500;
    line-height: 44px;
    letter-spacing: 0em;
    text-align: center;
    color: #FFFFFF;
}

.roleBox.fileUpload {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    column-gap: 20%;
}



input[type="file"]::file-selector-button {
    border-radius: 16px;
    height: 80px;
    width: 100%;
    cursor: pointer;
    background: linear-gradient(90.55deg, rgba(189, 189, 189, 0.4) 0.78%, rgba(189, 189, 189, 0.3) 100%);
    border: 1px solid rgba(0, 0, 0, 0.16);
    box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.05);
    margin-right: 16px;
    transition: background-color 200ms;
    font-family: Poppins;
    font-size: 20px;
    font-weight: 500;
    line-height: 44px;
    letter-spacing: 0em;
    text-align: center;
    color: #FFFFFF;
}

.fileSubmitBtn {
    width: 18%;
    height: 100%;
    border-radius: 16px;
    opacity: 0.6000000238418579px;
    align-self: center;
    background-color: #FBB600;
    border: none;
    padding: 20px;
    font-family: Poppins;
    font-size: 20px;
    font-weight: 500;
    line-height: 36px;
    letter-spacing: 0em;
    text-align: center;
    color: #FFFFFF;
    cursor: pointer;
}

@media only screen and (max-width: 480px) and (min-width: 320px) {

    .careerHeading {
        width: 100%;
        height: 20%;
        padding-top: 10%;
    }

    .ourcareerHeading {
        display: inline;
        text-align: center;
        margin: 10 auto;
        font-family: 'Akshar';
        font-style: normal;
        font-weight: 500;
        font-size: 56px;
        line-height: 84px;
        color: white;
        padding-top: 1rem;
        cursor: pointer;
        position: relative;
        text-decoration: none;
    }

    .ourcareerHeading:before {
        content: "";
        position: absolute;
        bottom: 15px;
        height: 10px;
        width: 30%;
        background: #FBB600;
        border-radius: 50px;
        transition: width 0.8s ease;
    }

    .ourcareerHeading:hover:before {
        width: 100%;
    }

    .bgColor {
        background: #164037;
        padding: 0% 0% 5% 7%;
        width: 100%;
    }

    .openingsHeading {
        display: flex;
        justify-content: flex-start;
        font-family: Akshar;
        font-size: 30px;
        font-weight: 500;
        line-height: 44px;
        letter-spacing: 0em;
        text-align: center;
        color: white;
        padding: 5% 0% 5% 0%;
    }

    .roleBox {
        display: flex;
        flex-direction: column;
        gap: 50px;
        width: 90%;
    }

    .jobRoleBox {
        height: 120px;
        border-radius: 16px;
        opacity: 0.6000000238418579px;
        background: linear-gradient(90.55deg, rgba(189, 189, 189, 0.4) 0.78%, rgba(189, 189, 189, 0.3) 100%);
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        padding: 2% 5% 2% 5%;
    }

    .jobroleTitle {
        font-family: Poppins;
        font-size: 20px;
        font-weight: 600;
        line-height: 60px;
        letter-spacing: 0em;
        color: white;
        align-self: center;
    }

    .jobroleApplybtn {
        width: 50%;
        height: 40px;
        border-radius: 16px;
        opacity: 0.6000000238418579px;
        align-self: center;
        /* background: linear-gradient(90.55deg, rgba(189, 189, 189, 0.4) 0.78%, rgba(189, 189, 189, 0.3) 100%); */
        background-color: #FBB600;
        border: none;
        font-family: Poppins;
        font-size: 20px;
        font-weight: 500;
        line-height: 36px;
        letter-spacing: 0em;
        text-align: center;
        color: #FFFFFF;
    }

    .showBg {
        background-image: url("../../common/Assets/CareerpageImages/human-resources-people-networking-concept.jpg");
        background-size: 500px;
        background-position: center center;
        background-repeat: no-repeat;
        background-attachment: fixed;
    }

    .bgImage {
        background-color: transparent;
        height: 20vh;
        z-index: -1;
    }

    .resumeSubmit {
        font-family: Akshar;
        font-size: 30px;
        font-weight: 500;
        line-height: 44px;
        letter-spacing: 0em;
        text-align: center;
        color: #FFFFFF;
    }

    .roleBox .fileUpload {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        column-gap: 50px;
    }

    input[type="file"]::file-selector-button {
        border-radius: 8px;
        height: 60px;
        align-self: center;
        width: 100%;
        cursor: pointer;
        background: linear-gradient(90.55deg, rgba(189, 189, 189, 0.4) 0.78%, rgba(189, 189, 189, 0.3) 100%);
        border: 1px solid rgba(0, 0, 0, 0.16);
        box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.05);
        transition: background-color 200ms;
        font-family: Poppins;
        font-size: 20px;
        font-weight: 500;
        line-height: 44px;
        letter-spacing: 0em;
        text-align: center;
        color: #FFFFFF;
    }

    .fileSubmitBtn {
        width: 50%;
        height: 40px;
        border-radius: 16px;
        opacity: 0.6000000238418579px;
        align-self: center;
        background-color: #FBB600;
        border: none;
        padding: 2px 10px 10px 10px;
        font-family: Poppins;
        font-size: 20px;
        font-weight: 500;
        line-height: 36px;
        letter-spacing: 0em;
        text-align: center;
        color: #FFFFFF;
    }
}

@media (min-width: 768px) and (max-width: 1024px) {

    .careerHeading {
        width: 100%;
        height: 20%;
        padding-top: 10%;
    }

    .ourcareerHeading {
        display: inline;
        text-align: center;
        margin: 10 auto;
        font-family: 'Akshar';
        font-style: normal;
        font-weight: 500;
        font-size: 64px;
        line-height: 84px;
        color: white;
        padding-top: 1rem;
        cursor: pointer;
        position: relative;
        text-decoration: none;
    }

    .ourcareerHeading:before {
        content: "";
        position: absolute;
        bottom: 15px;
        height: 10px;
        width: 30%;
        background: #FBB600;
        border-radius: 50px;
        transition: width 0.8s ease;
    }

    .ourcareerHeading:hover:before {
        width: 100%;
    }

    .bgColor {
        background: #164037;
        padding: 0% 0% 5% 7%;
        width: 100%;
    }

    .openingsHeading {
        display: flex;
        justify-content: flex-start;
        font-family: Akshar;
        font-size: 30px;
        font-weight: 500;
        line-height: 44px;
        letter-spacing: 0em;
        text-align: center;
        color: white;
        padding: 5% 0% 5% 0%;
    }

    .roleBox {
        display: flex;
        flex-direction: column;
        gap: 50px;
        width: 90%;
    }

    .jobRoleBox {
        height: 100px;
        border-radius: 16px;
        opacity: 0.6000000238418579px;
        background: linear-gradient(90.55deg, rgba(189, 189, 189, 0.4) 0.78%, rgba(189, 189, 189, 0.3) 100%);
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        padding: 2% 5% 2% 5%;
    }

    .jobroleTitle {
        font-family: Poppins;
        font-size: 20px;
        font-weight: 600;
        line-height: 60px;
        letter-spacing: 0em;
        color: white;
        align-self: center;
    }

    .jobroleApplybtn {
        width: 30%;
        height: 40px;
        border-radius: 8px;
        opacity: 0.6000000238418579px;
        align-self: center;
        /* background: linear-gradient(90.55deg, rgba(189, 189, 189, 0.4) 0.78%, rgba(189, 189, 189, 0.3) 100%); */
        background-color: #FBB600;
        border: none;
        font-family: Poppins;
        font-size: 20px;
        font-weight: 500;
        line-height: 36px;
        letter-spacing: 0em;
        text-align: center;
        color: #FFFFFF;
    }

    .showBg {
        background-image: url("../../common/Assets/CareerpageImages/human-resources-people-networking-concept.jpg");
        background-size: 500px;
        background-position: center center;
        background-repeat: no-repeat;
        background-attachment: fixed;
    }

    .bgImage {
        background-color: transparent;
        height: 20vh;
        z-index: -1;
    }

    .resumeSubmit {
        font-family: Akshar;
        font-size: 30px;
        font-weight: 500;
        line-height: 44px;
        letter-spacing: 0em;
        text-align: center;
        color: #FFFFFF;
    }

    .roleBox .fileUpload {
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        column-gap: 50px;
    }

    input[type="file"]::file-selector-button {
        border-radius: 8px;
        height: 60px;
        align-self: center;
        width: 100%;
        cursor: pointer;
        background: linear-gradient(90.55deg, rgba(189, 189, 189, 0.4) 0.78%, rgba(189, 189, 189, 0.3) 100%);
        border: 1px solid rgba(0, 0, 0, 0.16);
        box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.05);
        transition: background-color 200ms;
        font-family: Poppins;
        font-size: 20px;
        font-weight: 500;
        line-height: 44px;
        letter-spacing: 0em;
        text-align: center;
        color: #FFFFFF;
    }

    .fileSubmitBtn {
        width: 30%;
        height: 60px;
        border-radius: 8px;
        opacity: 0.6000000238418579px;
        align-self: center;
        background-color: #FBB600;
        border: none;
        padding: 2px 10px 10px 10px;
        font-family: Poppins;
        font-size: 20px;
        font-weight: 500;
        line-height: 36px;
        letter-spacing: 0em;
        text-align: center;
        color: #FFFFFF;
    }

}