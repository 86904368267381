.TermsOfUseHeading {
    width: 100%;
    height: 20%;
    padding-top: 50px;
}
  
.TermsOfUseHeading2 {
    display: inline;
    text-align: center;
    margin: 10 auto;
    font-family: 'Akshar';
    font-style: normal;
    font-weight: 500;
    font-size: 70px;
    line-height: 84px;
    color: black;
    padding-top: 1rem;
    cursor: pointer;
    position: relative;
    text-decoration: none;
}
  
  .TermsOfUseHeading2:before {
    content: "";
    position: absolute;
    bottom: 20px;
    height: 10px;
    width: 30%;
    background: #FBB600;
    border-radius: 50px;
    transition: width 0.8s ease;
  }
  
  .TermsOfUseHeading2:hover:before {
    width: 100%;
  }
  
  .TermsOfUseContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
  }

  .paragraphCenter {
    margin-top: -10% !important;
    padding: 250px !important;
    text-align: center !important;
}
  
  .aboutTextBox {
    text-align: justify;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 26px;
    line-height: 45px;
    padding: 0 0 0 50px;
    margin-top: 100px;
  }
  
  .TermsOfUseContainer .main {
    width: 650px;
    height: 650px;
    position: relative;
    padding: 50px;
  }
  
  
  @media only screen and (max-width: 480px) and (min-width: 320px) {
  
    .TermsOfUseHeading {
      width: 100%;
      height: 20%;
      padding-top: 50px;
    }
  
    .TermsOfUseHeading2 {
      display: inline;
      text-align: center;
      margin: 10 auto;
      font-family: 'Akshar';
      font-style: normal;
      font-weight: 500;
      font-size: 56px;
      line-height: 84px;
      color: black;
      padding-top: 1rem;
      cursor: pointer;
      position: relative;
      text-decoration: none;
    }
  
    .TermsOfUseHeading2:before {
      content: "";
      position: absolute;
      bottom: 15px;
      height: 10px;
      width: 30%;
      background: #FBB600;
      border-radius: 50px;
      transition: width 0.8s ease;
    }
  
    .TermsOfUseHeading2:hover:before {
      width: 100%;
    }
  
    .TermsOfUseContainer {
      display: flex;
      flex-direction: column-reverse;
      justify-content: space-evenly;
      align-items: center;
    }
  
    .aboutTextBox {
      text-align: justify;
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 400;
      font-size: 20px;
      line-height: 45px;
      justify-content: center;
      align-items: center;
      padding: 0px 20px 20px 20px;
      margin: 10px auto;
    }
  
    .TermsOfUseContainer .main {
      width: 450px;
      height: 450px;
      position: relative;
      padding: 50px;
    }
  
  }
  
  
  @media (min-width: 768px) and (max-width: 1024px) {
  
  
    .TermsOfUseHeading {
      width: 100%;
      height: 20%;
      padding-top: 50px;
    }
  
    .TermsOfUseHeading2 {
      display: inline;
      text-align: center;
      margin: 10 auto;
      font-family: 'Akshar';
      font-style: normal;
      font-weight: 500;
      font-size: 56px;
      line-height: 84px;
      color: black;
      padding-top: 1rem;
      cursor: pointer;
      position: relative;
      text-decoration: none;
    }
  
    .TermsOfUseHeading2:before {
      content: "";
      position: absolute;
      bottom: 15px;
      height: 10px;
      width: 30%;
      background: #FBB600;
      border-radius: 50px;
      transition: width 0.8s ease;
    }
  
    .TermsOfUseHeading2:hover:before {
      width: 100%;
    }
  
    .TermsOfUseContainer {
      display: flex;
      flex-direction: column-reverse;
      justify-content: space-evenly;
      align-items: center;
    }
  
    .aboutTextBox {
      text-align: justify;
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 400;
      font-size: 20px;
      line-height: 45px;
      justify-content: center;
      align-items: center;
      padding: 0px 20px 20px 20px;
      margin: 10px auto;
    }
  
    .TermsOfUseContainer .main {
      width: 450px;
      height: 450px;
      position: relative;
      padding: 50px;
    }
  
  }