.ContactHeading {
    width: 100%;
    height: 20%;
    padding-top: 2%;
}

.ContactUsHeading {
    display: inline;
    text-align: center;
    margin: 10 auto;
    font-family: 'Akshar';
    font-style: normal;
    font-weight: 500;
    font-size: 70px;
    line-height: 84px;
    color: black;
    padding-top: 1rem;
    cursor: pointer;
    position: relative;
    text-decoration: none;
}

.ContactUsHeading:before {
    content: "";
    position: absolute;
    bottom: 20px;
    height: 10px;
    width: 30%;
    background: #FBB600;
    border-radius: 50px;
    transition: width 0.8s ease;
}

.ContactUsHeading:hover:before {
    width: 100%;
}

.contactContainer{
  
    width: 100%;
    height: 700px;
}
.ContactUsContainer {
    width: 90%;
    height: 650px;
    margin: 4% auto;
    border-radius: 16px;
    display: flex;
    flex-direction: row;
    border: 1px solid silver;
    justify-content: space-around;
}

.ContactUsImage .contactImg {
    width: 650px;
    height: 650px;
}



.ContactUsForm .inputForm {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    padding: 20px;
}



.inputForm .inputform {
    padding-inline: 15px;
    width: 530px;
    height: 52px;
    border: 1px solid #BBBBBB;
    border-radius: 8px;
}



::placeholder {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #8E8E8E;
}

.sendButton {
    width: 409px;
    height: 50px;
    background: #164037;
    border-radius: 8px;
    border: 1px solid #164037;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #FFFFFF;
    position: relative;
    box-shadow: 0px 10px 18px -8px rgba(19, 62, 52, 0.3);
    cursor: pointer;
 margin: 20px auto;
}

.container {
    width: 530px;
    display: flex;
    margin: 0 auto;
    padding-top: 20px;

}

.fixed-size-textarea {
    width: 100%;
    height: 120px;
    resize: none;
    box-sizing: border-box;
    border: 1px solid gold;
    padding-inline: 15px;
    border: 1px solid #BBBBBB;
    border-radius: 8px;
}

.fixed-size-textarea::placeholder {
    padding: 25px 10px 10px 0px;
    font-family: 'Poppins';
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #8E8E8E;

}

.errorMessage {
    color: red;
    position: absolute;
    margin-left: 100px;
    margin-top: -20px;
}

@media only screen and (max-width: 480px) and (min-width: 320px) {

    .ContactHeading {
        width: 100%;
        height: 20%;
        padding-top: 10%;
    }

    .ContactUsHeading {
        display: inline;
        text-align: center;
        margin: 10 auto;
        font-family: 'Akshar';
        font-style: normal;
        font-weight: 500;
        font-size: 56px;
        line-height: 84px;
        color: black;
        padding-top: 1rem;
        cursor: pointer;
        position: relative;
        text-decoration: none;
    }

    .ContactUsHeading:before {
        content: "";
        position: absolute;
        bottom: 15px;
        height: 10px;
        width: 30%;
        background: #FBB600;
        border-radius: 50px;
        transition: width 0.8s ease;
    }

    .ContactUsHeading:hover:before {
        width: 100%;
    }

    .ContactUsContainer {
        width: 90%;
        height: 650px;
        margin: 4% auto;
        border-radius: 16px;
        display: flex;
        flex-direction: row;
        border: 1px solid #FFFFFF;
        justify-content: space-around;
        background: #FFFFFFF7;
        box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;

    }

    .ContactUsImage .contactImg {
        display: none;
        width: 626px;
        height: 556px;
    }

    .ContactUsForm {
        padding-top: 50px;
    }

    .ContactUsForm .inputForm {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .inputForm .inputform {
        padding-inline: 15px;
        width: 300px;
        height: 52px;
        border: 1px solid #BBBBBB;
        border-radius: 8px;
        align-items: center;
        justify-content: center;
    }

    ::placeholder {
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: #8E8E8E;
    }

    .formInput {
        display: none;
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 30px;
        text-align: center;
        color: #4D4D4D;
        align-items: flex-start;
    }

    .sendButton {
        position: relative;
        width: 300px;
        height: 50px;
        background: #164037;
        border-radius: 8px;
        border: 1px solid #164037;
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: #FFFFFF;
        box-shadow: 0px 10px 18px -8px rgba(19, 62, 52, 0.3);
        left: 0px;
    }

    .container {
        width: 300px;
        display: flex;
        flex-direction: column;
        margin: 0 auto;
        padding-top: 20px;

    }

    .fixed-size-textarea {
        width: 100%;
        height: 75px;
        resize: none;
        box-sizing: border-box;
        border: 1px solid gold;
        padding-inline: 15px;
        border: 1px solid #BBBBBB;
        border-radius: 8px;
    }

    .errorMessage {
        color: red;
        position: absolute;
        margin-left: -140px;
        margin-top: -25px;
    }

}

@media (min-width: 768px) and (max-width: 1024px) {
    .ContactHeading {
        width: 100%;
        height: 20%;
        padding-top: 10%;
    }

    .ContactUsHeading {
        display: inline;
        text-align: center;
        margin: 10 auto;
        font-family: 'Akshar';
        font-style: normal;
        font-weight: 500;
        font-size: 56px;
        line-height: 84px;
        color: black;
        padding-top: 1rem;
        cursor: pointer;
        position: relative;
        text-decoration: none;
    }

    .ContactUsHeading:before {
        content: "";
        position: absolute;
        bottom: 15px;
        height: 10px;
        width: 30%;
        background: #FBB600;
        border-radius: 50px;
        transition: width 0.8s ease;
    }

    .ContactUsHeading:hover:before {
        width: 100%;
    }

    .ContactUsContainer {
        width: 90%;
        height: 650px;
        margin: 4% auto;
        border-radius: 16px;
        display: flex;
        flex-direction: column;
        border: 1px solid #FFFFFF;
        justify-content: space-around;
        background: #FFFFFFF7;
        box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    }

    .ContactUsImage .contactImg {
        width: 306px;
        height: 306px;
        display: none;
    }

   

    .inputForm .inputform {
        padding-inline: 15px;
        width: 500px;
        border: 1px solid #BBBBBB;
        border-radius: 8px;
    }

    ::placeholder {
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: #8E8E8E;
    }

    .formInput {
        display: none;
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 30px;
        text-align: center;
        color: #4D4D4D;
        align-items: flex-start;
    }

    .sendButton {
        position: relative;
        width: 500px;
        height: 50px;
        background: #164037;
        border-radius: 8px;
        border: 1px solid #164037;
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: #FFFFFF;
        box-shadow: 0px 10px 18px -8px rgba(19, 62, 52, 0.3);
        left: 0px;
    }


    .container {
        width: 500px;
        display: flex;
        margin: 0 auto;
        padding-top: 20px;

    }

    .fixed-size-textarea {
        width: 100%;
        height: 75px;
        resize: none;
        box-sizing: border-box;
        border: 1px solid gold;
        padding-inline: 15px;
        border: 1px solid #BBBBBB;
        border-radius: 8px;
    }

    .fixed-size-textarea::placeholder {
        padding: 25px 10px 10px 0px;
        font-family: 'Poppins';
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: #8E8E8E;

    }


    .errorMessage {
        color: red;
        position: absolute;
        margin-left: -240px;
        margin-top: -25px;
    }
}