@import url('https: //fonts.googleapis.com/css2?family=Akshar:wght@300;400;500;600;700&display=swap');

@import url('https: //fonts.googleapis.com/css2?family=Akshar:wght@300;400;500;600;700&family=Poppins:ital,wght@0,300;0,400;0,500;1,200;1,300;1,400;1,500&display=swap');

* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

.ServicesHeading {
    width: 100%;
    height: 20%;
    padding-top: 2%;
}

.OurServicesHeading {
    display: inline;
    text-align: center;
    margin: 9% auto;
    font-family: 'Akshar';
    font-style: normal;
    font-weight: 500;
    font-size: 70px;
    line-height: 84px;
    color: black;
    padding-top: 1rem;
    cursor: pointer;
    position: relative;
    text-decoration: none;
}

.OurServicesHeading:before {
    content: "";
    position: absolute;
    bottom: 20px;
    height: 10px;
    width: 25%;
    background: #FBB600;
    border-radius: 50px;
    transition: width 0.6s ease;
}

.OurServicesHeading:hover:before {
    width: 100%;
}

.serviceContainer {
    position: relative;
    width: 100%;
    flex-direction: row;
    display: flex;
    justify-content: space-evenly;
    width: 100%;
    padding: 150px 0px 50px 0px;
    flex-wrap: wrap;
    row-gap: 200px;
}

.box {
    width: 410px;
    display: flex;
    flex-direction: row;
}

.box .card {
    position: relative;
    text-align: center;
    width: 410px;
    height: 550px;
    border-radius: 16px;
    padding: 40px 30px;
    color: black;
    background: #F5F5F5;
    background: linear-gradient(241deg, #E4E4E4 0%, #FEFEFE 73.56%);
    box-shadow: 14px 32px 134px 0px #2E2E2E2B;
    box-shadow: -8px 8px 18px 0px #FFFFFF inset;
    box-shadow: 7px -6px 18px 0px #E5E5E5 inset;
}

.box .card:before {
    content: "";
    position: absolute;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 0%;
    z-index: 0;
    background-color: transparent;
    border-radius: 16px;
    transition: all 0.6s;
}

.box .card:hover:before {
    height: 100%;
    background: linear-gradient(241deg, #FBB600 0%, #FFD461 73.56%);
    box-shadow: -14px 32px 134px 0px #FFD4612B;
    box-shadow: -8px 8px 18px 0px #FFD461 inset;
    box-shadow: 7px -6px 18px 0px #FFD461 inset;
}

.logoBox {
    height: 220px;
    width: 220px;
    border-radius: 50%;
    margin: -150px auto;
}

.logoBox:before {
    content: "";
    position: absolute;
    bottom: 0;
    height: 0;
    width: 1%;
    transition: width 0.1s ease;
}

.logoBox:hover :before {
    width: 100%;
    background: linear-gradient(241deg, #FBB600 0%, #FFD461 73.56%);
    box-shadow: -14px 32px 134px 0px #FFD4612B;
    box-shadow: -8px 8px 18px 0px #FFD461 inset;
    box-shadow: 7px -6px 18px 0px #FFD461 inset;
    transition: width 0.1s ease;
}



.dynamicLogoBox {
    background: linear-gradient(241deg, #FBB600 0%, #FFD461 73.56%);
    box-shadow: -14px 32px 134px 0px #FFD4612B;
    box-shadow: -8px 8px 18px 0px #FFD461 inset;
    box-shadow: 7px -6px 18px 0px #FFD461 inset;
    transition: all s;
}

.logoContainer {
    position: absolute;
    top: -100px;
    left: 155px;
    transform: translateX(-50px);
    text-align: center;
    height: 200px;
    width: 200px;
    border: 10px solid (#FFFFFF 100%);
    border-color: (#FFFFFF 100%);
    background:
        linear-gradient(180deg, #EDEDED 0%, #FFFFFF 100%);
    box-shadow: 1.55121929366832e-15px 25.33333396911621px 26.670000076293945px 0px #3333331A;
    box-shadow: 6.377234826912374e-16px 10.414814949035645px 10.414814949035645px 0px #FFFFFF inset;
    box-shadow: -6.377234826912374e-16px -10.414814949035645px 10.414814949035645px 0px #D7D7D7 inset;
    border-radius: 50%;
}

.appLogo {
    position: absolute;
    background-color: transparent;
    width: 100px;
    margin: 50px -50px;
}

.box .card .contentHeading {
    font-family: 'Akshar';
    font-style: normal;
    font-weight: 500;
    font-size: 40px;
    line-height: 44px;
    text-align: center;
    color: #164037;
    transition: 0.5s;
    position: relative;
    margin-top: 50%;
}

.box .card .contentText {
    position: relative;
    margin-top: 10%;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 27px;
    text-align: center;
    color: #164037;
}


@media only screen and (max-width: 480px) and (min-width: 320px) {

    .ServicesHeading {
        width: 100%;
        height: 20%;
        padding-bottom: 0%;
    }

    .OurServicesHeading {
        display: inline;
        text-align: center;
        margin: 9% auto;
        font-family: 'Akshar';
        font-style: normal;
        font-weight: 500;
        font-size: 56px;
        line-height: 84px;
        color: black;
        padding-top: 1rem;
        cursor: pointer;
        position: relative;
        text-decoration: none;

    }

    .OurServicesHeading:before {
        content: "";
        position: absolute;
        bottom: 15px;
        height: 10px;
        width: 25%;
        background: #FBB600;
        border-radius: 50px;
        transition: width 0.6s ease;
    }

    .OurServicesHeading:hover:before {
        width: 100%;
    }

    .serviceContainer {
        width: 100%;
        display: flex;
        flex-direction: column;
        width: 100%;
    }

    .box {
        width: 350px;
        display: flex;
        flex-direction: row;
        margin: 0 auto;

    }

    .box .card {
        position: relative;
        text-align: center;
        width: 350px;
        height: 600px;
        border-radius: 16px;
        color: black;
        margin: 0 auto;
        background: #F5F5F5;
        background: linear-gradient(241deg, #E4E4E4 0%, #FEFEFE 73.56%);
        box-shadow: 14px 32px 134px 0px #2E2E2E2B;
        box-shadow: -8px 8px 18px 0px #FFFFFF inset;
        box-shadow: 7px -6px 18px 0px #E5E5E5 inset;
        box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    }

    .box .card:before {
        content: "";
        position: absolute;
        bottom: 0;
        right: 0;
        width: 100%;
        height: 0%;
        z-index: 0;
        background-color: transparent;
        border-radius: 16px;
        transition: all 0.6s;
    }

    .box .card:hover:before {
        height: 100%;
        background: linear-gradient(241deg, #FBB600 0%, #FFD461 73.56%);
        box-shadow: -14px 32px 134px 0px #FFD4612B;
        box-shadow: -8px 8px 18px 0px #FFD461 inset;
        box-shadow: 7px -6px 18px 0px #FFD461 inset;
    }

    .logoBox {
        height: 220px;
        width: 220px;
        border-radius: 50%;
        margin: -150px auto;
    }

    .logoBox:before {
        content: "";
        position: absolute;
        bottom: 0;
        height: 0;
        width: 1%;
        transition: width 0.1s ease;
    }

    .logoBox:hover :before {
        width: 100%;
        background: linear-gradient(241deg, #FBB600 0%, #FFD461 73.56%);
        box-shadow: -14px 32px 134px 0px #FFD4612B;
        box-shadow: -8px 8px 18px 0px #FFD461 inset;
        box-shadow: 7px -6px 18px 0px #FFD461 inset;
        transition: width 0.1s ease;
    }

    .dynamicLogoBox {
        background: linear-gradient(241deg, #FBB600 0%, #FFD461 73.56%);
        box-shadow: -14px 32px 134px 0px #FFD4612B;
        box-shadow: -8px 8px 18px 0px #FFD461 inset;
        box-shadow: 7px -6px 18px 0px #FFD461 inset;
        transition: all s;
    }

    .logoContainer {
        position: absolute;
        top: -100px;
        left: 125px;
        transform: translateX(-50px);
        text-align: center;
        height: 200px;
        width: 200px;
        border: 10px solid (#FFFFFF 100%);
        border-color: (#FFFFFF 100%);
        background:
            linear-gradient(180deg, #EDEDED 0%, #FFFFFF 100%);
        box-shadow: 1.55121929366832e-15px 25.33333396911621px 26.670000076293945px 0px #3333331A;
        box-shadow: 6.377234826912374e-16px 10.414814949035645px 10.414814949035645px 0px #FFFFFF inset;
        box-shadow: -6.377234826912374e-16px -10.414814949035645px 10.414814949035645px 0px #D7D7D7 inset;
        border-radius: 50%;
    }

    .appLogo {
        position: absolute;
        width: 100px;
        margin: 50px -50px;
     background: transparent;
    }

    .box .card .contentHeading {
        font-family: 'Akshar';
        font-style: normal;
        font-weight: 500;
        font-size: 30px;
        line-height: 44px;
        text-align: center;
        color: #164037;
        transition: 0.5s;
        position: relative;
        margin-top: 60%;
    }

    .box .card .contentText {
        position: relative;
        margin-top: 10%;
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 27px;
        text-align: center;
        color: #164037;
    }
}


@media (min-width: 768px) and (max-width: 1024px) {

    .ServicesHeading {
        width: 100%;
        height: 20%;
        padding-bottom: 10%;
    }

    .OurServicesHeading {
        display: inline;
        text-align: center;
        margin: 5% auto;
        font-family: 'Akshar';
        font-style: normal;
        font-weight: 500;
        font-size: 64px;
        line-height: 84px;
        color: black;
        padding-top: 1rem;
        cursor: pointer;
        position: relative;
        text-decoration: none;

    }

    .OurServicesHeading:before {
        content: "";
        position: absolute;
        bottom: 15px;
        height: 10px;
        width: 25%;
        background: #FBB600;
        border-radius: 50px;
        transition: width 0.6s ease;
    }

    .OurServicesHeading:hover:before {
        width: 100%;
    }

    .serviceContainer {
        width: 100%;
        display: flex;
        flex-direction: column;

    }

    .box {
        width: 550px;
        display: flex;
        flex-direction: row;
        margin: 0 auto;

    }

    .box .card {
        position: relative;
        text-align: center;
        width: 550px;
        height: 400px;
        border-radius: 16px;
        color: black;
        margin: 0 auto;
        background: #F5F5F5;
        background: linear-gradient(241deg, #E4E4E4 0%, #FEFEFE 73.56%);
        box-shadow: 14px 32px 134px 0px #2E2E2E2B;
        box-shadow: -8px 8px 18px 0px #FFFFFF inset;
        box-shadow: 7px -6px 18px 0px #E5E5E5 inset;
        box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    }

    .box .card:before {
        content: "";
        position: absolute;
        bottom: 0;
        right: 0;
        width: 100%;
        height: 0%;
        z-index: 0;
        background-color: transparent;
        border-radius: 16px;
        transition: all 0.6s;
    }

    .box .card:hover:before {
        height: 100%;
        background: linear-gradient(241deg, #FBB600 0%, #FFD461 73.56%);
        box-shadow: -14px 32px 134px 0px #FFD4612B;
        box-shadow: -8px 8px 18px 0px #FFD461 inset;
        box-shadow: 7px -6px 18px 0px #FFD461 inset;
    }

    .logoBox {
        height: 220px;
        width: 220px;
        border-radius: 50%;
        margin: -150px auto;
    }

    .logoBox:before {
        content: "";
        position: absolute;
        bottom: 0;
        height: 0;
        width: 1%;
        transition: width 0.1s ease;
    }

    .logoBox:hover :before {
        width: 100%;
        background: linear-gradient(241deg, #FBB600 0%, #FFD461 73.56%);
        box-shadow: -14px 32px 134px 0px #FFD4612B;
        box-shadow: -8px 8px 18px 0px #FFD461 inset;
        box-shadow: 7px -6px 18px 0px #FFD461 inset;
        transition: width 0.1s ease;
    }

    .dynamicLogoBox {
        background: linear-gradient(241deg, #FBB600 0%, #FFD461 73.56%);
        box-shadow: -14px 32px 134px 0px #FFD4612B;
        box-shadow: -8px 8px 18px 0px #FFD461 inset;
        box-shadow: 7px -6px 18px 0px #FFD461 inset;
        transition: all s;
    }

    .logoContainer {
        position: absolute;
        top: -100px;
        left: 225px;
        transform: translateX(-50px);
        text-align: center;
        height: 200px;
        width: 200px;
        border: 10px solid (#FFFFFF 100%);
        border-color: (#FFFFFF 100%);
        background:
            linear-gradient(180deg, #EDEDED 0%, #FFFFFF 100%);
        box-shadow: 1.55121929366832e-15px 25.33333396911621px 26.670000076293945px 0px #3333331A;
        box-shadow: 6.377234826912374e-16px 10.414814949035645px 10.414814949035645px 0px #FFFFFF inset;
        box-shadow: -6.377234826912374e-16px -10.414814949035645px 10.414814949035645px 0px #D7D7D7 inset;
        border-radius: 50%;
    }

    .appLogo {
        position: absolute;
      background: transparent;
        width: 100px;
        margin: 50px -50pxx;
    }

    .box .card .contentHeading {
        font-family: 'Akshar';
        font-style: normal;
        font-weight: 500;
        font-size: 36px;
        line-height: 44px;
        text-align: center;
        color: #164037;
        transition: 0.5s;
        position: relative;
        margin: 30% auto;
    }

    .box .card .contentText {
        position: relative;
        margin-top: -25%;
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 27px;
        text-align: center;
        color: #164037;
    }


}