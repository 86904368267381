* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.backgroundImage {
  overflow-x: hidden;
  overflow-y: hidden;
  height: 650px;
}

.backgroundImage .homeText {
  position: relative;
  width: 100%;
  font-family: 'Akshar';
  font-style: normal;
  font-weight: 400;
  font-size: 64px;
  line-height: 92px;
  color: white;
  padding: 100px;
  z-index: 1000;
  animation-name: text;
  animation-duration: 6s;
  animation-fill-mode: forwards;
}

.globe {
  width: 100%;
}

.globeImg {
  width: 100%;
  height: 1000px;
  text-align: center;
  justify-content: center;
  position: relative;
  margin-top: -380px;
  animation-name: grow;
  animation-duration: 10s;
  animation-fill-mode: forwards;
}

@keyframes grow {
  0% {
    margin-top: -600px;
    background-image: url('../../common/Assets/HomePageImages/backgroundOne.png');
    background-position: center;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    background-attachment: fixed;
  }

  100% {
    margin-top: -500px;
    background-image: url('../../common/Assets/HomePageImages/backgroundTwo.png');
    background-position: center;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    background-attachment: fixed;
  }
}

@media only screen and (max-width: 600px) and (min-width: 320px) {
  
  .backgroundImage .homeText {
    display: inline;
    position: relative;
    font-family: 'Akshar';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 20px;
    color: white;
    z-index: 1000;
    animation-name: text;
    animation-duration: 6s;
    animation-fill-mode: forwards;
  }

  .backgroundImage {
    overflow-x: hidden;
    overflow-y: hidden;
    height: 30vh;
  }

  .globe {
    width: 100%;
  }

  .globeImg {
    width: 100%;
    height: 300px;
    text-align: center;
    justify-content: center;
    position: relative;
    animation-name: mobile;
    animation-duration: 20s;
    animation-fill-mode: forwards;
    margin-top: -180px;
  }

  @keyframes mobile {
    0% {
      margin-top: -180px;
      background-image: url('../../common/Assets/HomePageImages/backgroundOne.png');
      background-position: center;
      background-size: cover;
      background-position: center center;
      background-repeat: no-repeat;
      background-attachment: fixed;
    }

    100% {
      margin-top: -130px;
      background-image: url('../../common/Assets/HomePageImages/backgroundTwo.png');
      background-position: center;
      background-size: cover;
      background-position: center center;
      background-repeat: no-repeat;
      background-attachment: fixed;
    }
  }
}

@media (min-width: 768px) and (max-width: 1024px) {

  .backgroundImage .homeText {
    display: inline;
    position: relative;
    font-family: 'Akshar';
    font-style: normal;
    font-weight: 400;
    font-size: 28px;
    line-height: 30px;
    color: white;
    z-index: 1000;
    animation-name: text;
    animation-duration: 6s;
    animation-fill-mode: forwards;
  }

  .backgroundImage {
    overflow-x: hidden;
    overflow-y: hidden;
    height: 30vh;
  }

  .globe {
    width: 100%;
  }

  .globeImg {
    width: 100%;
    height: 300px;
    text-align: center;
    justify-content: center;
    position: relative;
    animation-name: mobile;
    animation-duration: 20s;
    animation-fill-mode: forwards;
    margin-top: -180px;
  }

  @keyframes mobile {
    0% {
      margin-top: -180px;
      background-image: url('../../common/Assets/HomePageImages/backgroundOne.png');
      background-position: center;
      background-size: cover;
      background-position: center center;
      background-repeat: no-repeat;
      background-attachment: fixed;
    }

    100% {
      margin-top: -130px;
      background-image: url('../../common/Assets/HomePageImages/backgroundTwo.png');
      background-position: center;
      background-size: cover;
      background-position: center center;
      background-repeat: no-repeat;
      background-attachment: fixed;
    }
  }
}