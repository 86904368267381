.footer {
    max-width: 100%;
    width: 100%;
    display: flex;
    justify-content: space-between;
    background: black;
    height: 50px;

}



.zyxanRights {
    font-family: 'Poppins';
    text-align: center;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 36px;
    color: #FFFFFF;
    padding-top: 5px;
    cursor: pointer;
    margin: 0 auto;
}

.socialMedia {
    display: flex;
    justify-content: space-between;
    gap: 12px;
}

.socialMediaIcon {
    padding-top: 10px;
    width: 25px;
    height: 35px;
    cursor: pointer;
}


.addressFooter{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    background: #164037;
    padding: 20px;
}

.usAddress{
text-align: left;
}

.indianAddress{
    text-align: left;  
}

.address{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: #FFFFFF;
    padding-top: 5px;
    cursor: pointer;
    margin: 0 auto;
    text-decoration: none;
}

.nameHeading{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 24px;
    color: #FFFFFF;
    padding-top: 5px;
    cursor: pointer;
    
}



@media only screen and (max-width: 480px) and (min-width: 320px) {
    .footer {
        max-width: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        background: black;
        height: 60px;

    }


    .zyxanRights {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 36px;
        color: #FFFFFF;
        padding-top: 5px;
        cursor: pointer;
    }

    .socialMedia {
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        padding: 0% 30% 0% 30%;
    }

    .socialMedia .socialMediaIcon {
        width: 25px;
        height: 35px;
        cursor: pointer;
    }

    
.addressFooter{
    width: 100%;
    display: flex;
    flex-direction: column;
    background: #164037;
    padding: 20px;
  
  

}

.usAddress{
text-align: center;
padding: 20px;
}

.indianAddress{
    text-align: center;
padding: 20px;
}

.address{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: #FFFFFF;
    padding-top: 5px;
    cursor: pointer;
    margin: 0 auto;
}

.nameHeading{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 24px;
    color: #FFFFFF;
    padding-top: 5px;
    cursor: pointer;
    
}
}


@media (min-width: 768px) and (max-width: 1024px) {

    .footer {
        max-width: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        background: black;
        height: 50px;
    }


    .zyxanRights {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 36px;
        color: #FFFFFF;
        padding-top: 5px;
        cursor: pointer;
    }

    .socialMedia {
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        padding: 0% 30% 0% 30%;
    }

    .socialMedia .socialMediaIcon {
        width: 25px;
        height: 35px;
        cursor: pointer;
    }

    
.addressFooter{
    width: 100%;
    display: flex;
    flex-direction: column;
    background: #164037;
    padding: 20px;
  
  

}

.usAddress{
text-align: center;
padding: 20px;
}

.indianAddress{
    text-align: center;
padding: 20px;
}

.address{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #FFFFFF;
    padding-top: 5px;
    cursor: pointer;
    margin: 0 auto;
}

.nameHeading{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 24px;
    color: #FFFFFF;
    padding-top: 5px;
    cursor: pointer;
    
}
    

}