.clientHeading {
    width: 100%;
    height: 20%;
    padding-top: 2%;
}

.ourClientsHeading {
    display: inline;
    text-align: center;
    margin: 10 auto;
    font-family: 'Akshar';
    font-style: normal;
    font-weight: 500;
    font-size: 70px;
    line-height: 84px;
    color: black;
    padding-top: 1rem;
    cursor: pointer;
    position: relative;
    text-decoration: none;
}

.ourClientsHeading:before {
    content: "";
    position: absolute;
    bottom: 20px;
    height: 10px;
    width: 20%;
    background: #FBB600;
    border-radius: 50px;
    transition: width 0.8s ease;
}

.ourClientsHeading:hover:before {
    width: 100%;
}

.clientProjectHeading {
    width: 100%;
    height: 20%;
}

.ourClientProjectHeading {
    display: inline;
    text-align: center;
    margin: 10 auto;
    font-family: 'Akshar';
    font-style: normal;
    font-weight: 500;
    font-size: 76px;
    line-height: 84px;
    color: white;
    padding-top: 1rem;
    cursor: pointer;
    position: relative;
    text-decoration: none;
}

.ourClientProjectHeading:before {
    content: "";
    position: absolute;
    bottom: 20px;
    height: 10px;
    width: 20%;
    background: #FBB600;
    border-radius: 50px;
    transition: width 0.8s ease;
}

.ourClientProjectHeading:hover:before {
    width: 100%;
}

.ourClientAboutContainer {
    padding: 1%;
}

.slide {
    border: 1px solid #ccc;
    padding: 20px;
    transition: transform 0.3s;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.16);
    border-radius: 15px;
    text-align: center;
    align-self: center;
    height: 440px;
    width: 300px !important;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.slide img {
    width: 80px;
    height: 80px;
    border-radius: 50px;
    align-items: center;
    align-self: center;
    margin: 0 auto;
}

.cardTitle {
    position: relative;
    font-family: Poppins;
    font-size: 32px;
    font-weight: 500;
    line-height: 48px;
    text-align: center;
    align-self: center;
}

.cardDescription {
    font-family: Poppins;
    font-size: 16px;
    line-height: 30px;
    letter-spacing: 0em;
    margin: 6% auto;
    font-weight: 400;
}

.block {
    width: 100%;
    height: 10vh;
}

.slick-slide>div {
    margin: 50px 10px;
}

.active-item {
    transform: scale(1.1);
}

@media only screen and (max-width: 480px) and (min-width: 320px) {

    .clientHeading {
        width: 100%;
        height: 20%;
        padding-top: 2%;
    }

    .ourClientsHeading {
        display: inline;
        text-align: center;
        margin: 10 auto;
        font-family: 'Akshar';
        font-style: normal;
        font-weight: 500;
        font-size: 56px;
        line-height: 84px;
        color: black;
        padding-top: 1rem;
        cursor: pointer;
        position: relative;
        text-decoration: none;
    }

    .ourClientsHeading:before {
        content: "";
        position: absolute;
        bottom: 15px;
        height: 10px;
        width: 20%;
        background: #FBB600;
        border-radius: 50px;
        transition: width 0.8s ease;
    }

    .ourClientsHeading:hover:before {
        width: 100%;
    }

    .ourClientAboutContainer {
        padding: 1%;
    }

    .slide {
        border: 1px solid #ccc;
        transition: transform 0.3s;
        box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.16);
        border-radius: 15px;
        text-align: center;
        align-self: center;
        height: 400px;
        width: 270px !important;
        box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    }

    .slide img {
        width: 50px;
        height: 50px;
        border-radius: 50px;
        align-items: center;
        align-self: center;
        margin: 0 auto;
    }

    .cardTitle {
        position: relative;
        font-family: Poppins;
        font-size: 24px;
        font-weight: 500;
        line-height: 48px;
        text-align: center;
        align-self: center;
    }

    .cardDescription {
        font-family: Poppins;
        font-size: 14px;
        line-height: 30px;
        letter-spacing: 0em;
        font-weight: 400;
    }

    .block {
        width: 100%;
        height: 10vh;
    }

    .slick-slide>div {
        margin: 50px 10px;
    }

    .active-item {
        transform: scale(1.1);
    }
}




/* clients css */

.ourClientContainer {
    background: hsl(167, 49%, 17%);
    width: 100%;
    margin: 0px auto;
    padding: 5% 0% 5% 0%;
}

.client {
    border: 1px solid #ccc;
    padding: 20px;
    transition: transform 0.3s;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.16);
    border-radius: 15px;
    text-align: center;
    align-self: center;
    height: 450px;
    width: 400px !important;
    background: linear-gradient(138.03deg, rgba(189, 189, 189, 0.4) 23.68%, rgba(189, 189, 189, 0.3) 76.32%);
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.clientproImg {
    width: 150px;
    height: 150px;
    border-radius: 100px;
    align-items: center;
    align-self: center;
    margin: 0 auto;
}

.clentproTitle {
    padding: 10px;
    position: relative;
    font-family: Poppins;
    font-size: 32px;
    font-weight: 500;
    line-height: 48px;
    text-align: center;
    align-self: center;
    color: white;
}

.clinetprojectDescription {
    font-family: Poppins;
    font-size: 16px;
    line-height: 30px;
    letter-spacing: 0em;
    color: white;
    font-weight: 100;
}

.block {
    width: 100%;
    height: 10vh;
}

.slick-slide>div {
    margin: 50px 10px;
}

@media only screen and (max-width: 480px) and (min-width: 360px) {

    .clientProjectHeading {
        width: 100%;
        height: 20%;
    }

    .ourClientProjectHeading {
        display: inline;
        text-align: center;
        margin: 10 auto;
        font-family: 'Akshar';
        font-style: normal;
        font-weight: 500;
        font-size: 56px;
        line-height: 84px;
        color: white;
        padding-top: 1rem;
        cursor: pointer;
        position: relative;
        text-decoration: none;
    }

    .ourClientProjectHeading:before {
        content: "";
        position: absolute;
        bottom: 15px;
        height: 10px;
        width: 20%;
        background: #FBB600;
        border-radius: 50px;
        transition: width 0.8s ease;
    }

    .ourClientProjectHeading:hover:before {
        width: 100%;
    }

    .ourClientContainer {
        background: hsl(167, 49%, 17%);
        width: 100%;
        margin: 0px auto;
    }

    .client {
        border: 1px solid #ccc;
        transition: transform 0.3s;
        box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.16);
        border-radius: 15px;
        text-align: center;
        align-self: center;
        height: 400px;
        width: 220px !important;
        background: linear-gradient(90.55deg, rgba(189, 189, 189, 0.4) 0.78%, rgba(189, 189, 189, 0.3) 100%);
        box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    }

    .clientproImg {
        width: 80px;
        height: 80px;
        border-radius: 50px;
        align-items: center;
        align-self: center;
        margin: 0 auto;
    }

    .clentproTitle {
        position: relative;
        font-family: Poppins;
        font-size: 24px;
        font-weight: 500;
        line-height: 48px;
        text-align: center;
        align-self: center;
        color: white;
    }

    .clinetprojectDescription {
        font-family: Poppins;
        font-size: 14px;
        line-height: 30px;
        letter-spacing: 0em;
        color: white;
        font-weight: 100;
    }

    .slick-slide>div {
        margin: 50px 10px;
    }

}



@media (min-width: 768px) and (max-width: 1024px) {

    .clientProjectHeading {
        width: 100%;
        height: 20%;
        padding-top: 2%;
    }

    .ourClientProjectHeading {
        display: inline;
        text-align: center;
        margin: 10 auto;
        font-family: 'Akshar';
        font-style: normal;
        font-weight: 500;
        font-size: 64px;
        line-height: 84px;
        color: white;
        padding-top: 1rem;
        cursor: pointer;
        position: relative;
        text-decoration: none;
    }

    .ourClientProjectHeading:before {
        content: "";
        position: absolute;
        bottom: 15px;
        height: 10px;
        width: 20%;
        background: #FBB600;
        border-radius: 50px;
        transition: width 0.8s ease;
    }

    .ourClientProjectHeading:hover:before {
        width: 100%;
    }

    .ourClientContainer {
        background: hsl(167, 49%, 17%);
        width: 100%;
        margin: 5% auto;
        padding: 5% 0% 5% 0%;
    }

    .client {
        border: 1px solid #ccc;
        transition: transform 0.3s;
        box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.16);
        border-radius: 15px;
        text-align: center;
        align-self: center;
        height: 330px;
        width: 280px !important;
        background: linear-gradient(90.55deg, rgba(189, 189, 189, 0.4) 0.78%, rgba(189, 189, 189, 0.3) 100%);
        box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    }

    .clientproImg {
        width: 80px;
        height: 80px;
        border-radius: 50px;
        align-items: center;
        align-self: center;
        margin: 0 auto;
    }

    .clentproTitle {
        position: relative;
        font-family: Poppins;
        font-size: 24px;
        font-weight: 500;
        line-height: 48px;
        text-align: center;
        align-self: center;
        color: white;
    }

    .clinetprojectDescription {
        font-family: Poppins;
        font-size: 14px;
        line-height: 30px;
        letter-spacing: 0em;
        color: white;
        font-weight: 100;
    }

    .block {
        width: 100%;
        height: 10vh;
    }

    .active-item {
        transform: scale(1.1);
    }

    .clientHeading {
        width: 100%;
        height: 20%;
        padding-top: 2%;
    }

    .ourClientsHeading {
        display: inline;
        text-align: center;
        margin: 10 auto;
        font-family: 'Akshar';
        font-style: normal;
        font-weight: 500;
        font-size: 64px;
        line-height: 84px;
        color: black;
        padding-top: 1rem;
        cursor: pointer;
        position: relative;
        text-decoration: none;
    }

    .ourClientsHeading:before {
        content: "";
        position: absolute;
        bottom: 15px;
        height: 10px;
        width: 20%;
        background: #FBB600;
        border-radius: 50px;
        transition: width 0.8s ease;
    }

    .ourClientsHeading:hover:before {
        width: 100%;
    }

    .ourClientAboutContainer {
        padding: 1%;
    }

    .slide {
        border: 1px solid #ccc;
        transition: transform 0.3s;
        box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.16);
        border-radius: 15px;
        text-align: center;
        align-self: center;
        height: 400px;
        width: 280px !important;
        box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    }

    .slide img {
        width: 50px;
        height: 50px;
        border-radius: 50px;
        align-items: center;
        align-self: center;
        margin: 0 auto;
    }

    .cardTitle {
        position: relative;
        font-family: Poppins;
        font-size: 24px;
        font-weight: 500;
        line-height: 48px;
        text-align: center;
        align-self: center;
    }

    .cardDescription {
        font-family: Poppins;
        font-size: 14px;
        line-height: 30px;
        letter-spacing: 0em;
        font-weight: 400;
    }

    .block {
        width: 100%;
        height: 10vh;
    }

    .slick-slide>div {
        margin: 50px 10px;
    }

    .active-item {
        transform: scale(1.1);
    }
}